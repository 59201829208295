import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dedicated_team from "../../../images/pricing-page/dedicated_team.svg"
import product_dev from "../../../images/pricing-page/product_dev.svg"
import resource_aug from "../../../images/pricing-page/resource_aug.svg"
import PricingCard from "./pricingCard"

const Pricing = () => {
  const staffCard = {
    icon: (
      <img
        src={resource_aug}
        alt="pricing_staff"
        decoding="async"
        loading="lazy"
        height={40}
      />
    ),
    heading: "Staff/Resource Augmentation",
    subHeading: "Hire top talent at a fraction of the cost",
    servicesList: [
      "Vetted developer profiles in 48 hours",
      "Staffing within 1-2 weeks",
      "Availability of developers in every tech stack",
      "No hiring and retention stress",
      "Management and payroll freedom",
      "Schedule 1 Week trial ",
    ],
    questions: {
      question1: {
        label: "How early do you need a developer?",
        value: "how_early_do_you_need_a_developer",
      },
      options1: [
        { value: "Within 1 Week", label: "Within 1 Week" },
        { value: "Within two weeks", label: "Within two weeks" },
        { value: "In a month", label: "In a month" },
        { value: "Not sure", label: "Not sure" },
      ],
      question2: { label: "For how long?", value: "for_how_long" },
      options2: [
        { value: "1-3 months", label: "1-3 months" },
        { value: "3-6 months", label: "3-6 months" },
        { value: "6-10 months", label: "6-10 months" },
        { value: "10+ months", label: "10+ months" },
      ],
      question3: {
        label: "Preferred tech stack?",
        value: "preferred_tech_stack",
      },
    },
  }

  const productDevCard = {
    icon: (
      <img
        src={product_dev}
        alt="product_dev"
        decoding="async"
        loading="lazy"
        height={40}
      />
    ),
    heading: "Product Development",
    subHeading: "Get your products built from scratch",
    servicesList: [
      "Free Cost estimation",
      "Expert UI/UX design",
      "Prototype and wireframing",
      "Premium code quality",
      "Free dedicated project management",
      "Free dedicated quality assurance",
      "Timely delivery",
      "Weekly sprints",
    ],
    questions: {
      question1: {
        label: "What are you looking to develop?",
        value: "what_are_you_looking_to_develop",
      },
      options1: [
        { value: "MVP", label: "MVP" },
        {
          value: "Complete Product Development",
          label: "Complete Product Development",
        },
        { value: "UI/UX Design", label: "UI/UX Design" },
        { value: "Working Prototype", label: "Working Prototype" },
        { value: "Something Else", label: "Something Else" },
      ],
      question2: { label: "For which platform?", value: "for_which_platform" },
      options2: [
        { value: "Web", label: "Web" },
        { value: "Mobile", label: "Mobile" },
        { value: "Desktop", label: "Desktop" },
        { value: "All of them", label: "All of them" },
      ],
      question3: {
        label: "Preferred tech stack?",
        value: "preferred_tech_stack",
      },
    },
  }

  const teamCard = {
    icon: (
      <img
        decoding="async"
        loading="lazy"
        src={dedicated_team}
        alt="dedicated_team"
        height={40}
      />
    ),
    heading: "Dedicated Team",
    subHeading: "Hire dedicated developers for scalable results",
    servicesList: [
      "Transparent pricing system",
      "Personal extended team exclusively working on your project",
      "Access to a wide range of IT experts from multiple domains",
      "Project manager allocated by InvoZone",
      "Team flexibility and scalability",
      "Direct communication with developers",
      "Extend and reduce team according to your requirements",
    ],
    questions: {
      question1: {
        label: "How many developers do you need?",
        value: "how_many_developers_do_you_need",
      },
      options1: [
        { value: "2-6 developers", label: "2-6 developers" },
        { value: "6-10 developers", label: "6-10 developers" },
        { value: "10+ developers", label: "10+ developers" },
      ],
      question2: { label: "For how long?", value: "for_how_long" },
      options2: [
        { value: "1-3 months", label: "1-3 months" },
        { value: "3-6 months", label: "3-6 months" },
        { value: "6-10 months", label: "6-10 months" },
        { value: "10+ months", label: "10+ months" },
      ],
      question3: {
        label: "Preferred tech stack?",
        value: "preferred_tech_stack",
      },
    },
  }

  return (
    <section className="pt-5" style={{ backgroundColor: "#f9f9f9" }}>
      <Container className="pt-5">
        <Row>
          <Col xs={12}>
            <h1 className="main-heading-36 text-center  pb-5">Our Pricing</h1>
          </Col>
          <Col xs={12} md={6} lg={6} xl={4}>
            <PricingCard
              data={staffCard}
              btnId={"pricing_page_staff_augmentation_btn_gt"}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={4}>
            <PricingCard
              data={teamCard}
              index={2}
              btnId={"pricing_page_dedicated_team_btn_gt"}
            />
          </Col>
          <Col xs={12} md={6} lg={6} xl={4}>
            <PricingCard
              data={productDevCard}
              index={1}
              btnId={"pricing_page_product_dev_btn_gt"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Pricing
